import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SpCDKModule, SpPlatform } from '@libs/cdk';
import { ChatAdapter } from '@portal/chats/shared';
import { ThemeModule } from '@portal/config';
import { ControlsModule } from '@portal/shared/components';
import { ButtonSizes, IButton } from '@portal/shared/components/controls';
import { UserQuery } from '@portal/user';
import { MomentModule } from 'ngx-moment';

@Component({
  standalone: true,
  imports: [ SpCDKModule, ControlsModule, MomentModule, ThemeModule ],
  templateUrl: './p2p-reset-credits-dialog.component.html',
  styleUrl: './p2p-reset-credits-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pResetCreditsDialogComponent {
  private readonly dialog = inject(DialogRef);
  private readonly chatAdapter = inject(ChatAdapter);
  private readonly userInfoQuery = inject(UserQuery);

  readonly okButton: IButton = { size: ButtonSizes.Medium };

  constructor() {
    if (inject(SpPlatform).server) { return; }
    this.chatAdapter.init(this.userInfoQuery.id, { manual: true });
  }

  openChat(): void { this.chatAdapter.open(); }

  close(): void { this.dialog.close(); }
}
