import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input } from '@angular/core';
import { Nullable, UnixTimer } from '@libs/utils';
import { P2pResetCreditsDialogComponent } from '@portal/payment/components/p2p-card/p2p-reset-credits-dialog';
import { P2pCardInfo } from '@portal/payment/shared/types/p2p-card-info';
import { ClipboardCopyComponent } from '@portal/shared/components/content/clipboard-copy';
import { SpCommonModule } from 'libs/cdk/src/lib/common';
import { SpLocalizationModule } from 'libs/cdk/src/lib/localization';
import { MomentModule } from 'ngx-moment';

@Component({
  standalone: true,
  selector: 'gg-payment-p2p-card',
  templateUrl: './p2p-card.component.html',
  styleUrl: './p2p-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCommonModule, SpLocalizationModule, ClipboardCopyComponent, MomentModule ]
})
export class P2pCardComponent {
  private ref: Nullable<DialogRef<boolean>> = null;
  private readonly dialog = inject(Dialog);
  private readonly destroy = inject(DestroyRef);

  @Input({ required: true }) timer: Nullable<UnixTimer>;
  @Input({ required: true }) card: Nullable<P2pCardInfo>;

  accountTransform(type: Nullable<string>, account: Nullable<string>): Nullable<string> {
    if (type !== 'p2p_card') { return account }

    return account?.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
  }

  openResetDialog(): void {
    this.dialog.open(P2pResetCreditsDialogComponent, { panelClass: [ 'bottom-center', 'bottom-center--new-cashier' ] });
  }
}
