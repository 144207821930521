import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, filter, map, Observable, tap } from 'rxjs';
import { first, switchMap, withLatestFrom } from 'rxjs/operators';
import { synchronize } from '@libs/store';
import { ControlAdapter, Nullable } from '@libs/utils';
import { Bonus } from '@portal/bonuses';
import { BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { UserQuery } from '@portal/user';

@Component({
  selector: 'gg-payment-bonus-button',
  templateUrl: './bonus-button.component.html',
  styleUrls: [ './bonus-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BonusButtonComponent implements OnInit {
  private readonly ngControl = inject(NgControl, { self: true });
  private readonly adapter: ControlAdapter<Nullable<Bonus>> = new ControlAdapter();
  private readonly bonusesData = inject(BonusesData);
  private readonly bonusesQuery = inject(BonusesQuery);
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly isCryptoMethodSubject = new BehaviorSubject<Nullable<boolean>>(false);
  private readonly isBonus: BehaviorSubject<Nullable<boolean>> = new BehaviorSubject<Nullable<boolean>>(false);

  readonly isBonus$: Observable<Nullable<boolean>> = this.isBonus.asObservable();
  readonly isBonusesEnabled = inject(UserQuery).isBonusesEnabled;
  readonly activeBonus$ = this.bonusesQuery.active$;

  showPromocodeInput: boolean = false;
  bonusesList$: Nullable<Observable<Array<Bonus>>> = null;

  get activeBonus(): Nullable<Bonus> { return this.bonusesQuery.active; }

  get isLinkedBonus(): boolean {
    return this.activeBonus?.type === 'casino_linked';
  }

  @Input()
  set amount(value: Nullable<number>) {
    this.bonusesQuery.selectAvailableDepositBonuses(String(value))
      .pipe(
        first(),
        filter((bonuses) => !!bonuses?.length && !!this.activeBonus))
      .subscribe(bonuses => {
        const isBonus = bonuses.some(bonus => bonus.id === this.activeBonus?.id);
        this.isBonus.next(isBonus);
      })
  }

  @Input() maxMethodPrice : Nullable<number>;

  @Input()
  set selectedBonus(value: Nullable<Bonus>) {
    this.selectBonus(value);
  }

  @Input()
  set isCryptoMethod(value: Nullable<boolean>) {
    this.isCryptoMethodSubject.next(value || false);
  }

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  get isCryptoMethod(): Nullable<boolean> {
    return this.isCryptoMethodSubject.value;
  }

  constructor() {
    synchronize(this.bonusesData, this.bonusesQuery);
    this.ngControl.valueAccessor = this.adapter.accessor;
    this.adapter.registerOnModelChange((value) => {
      if (value === null) {
        this.isBonus.next(false);
      } else {
        this.isBonus.next(true);
        this.bonusesData.selectBonus(value as Bonus, this.isCryptoMethod);
      }
    });
  }

  ngOnInit(): void {
    this.activeBonus && this.adapter.change(this.activeBonus);
    this.bonusesList$ = this.getBonusesList(this.maxMethodPrice);
    this.setActiveCryptoBonus();
  }

  promocodeActivated(): void {
    this.bonusesData.selectBonus(this.activeBonus as Bonus, this.isCryptoMethod);
    this.adapter.change(this.activeBonus);
  }

  private getBonusesList(value: Nullable<number>): Nullable<Observable<Array<Bonus>>> {
    return this.bonusesQuery.selectAvailableDepositBonuses(String(value)).pipe(
      filter((bonuses) => !!bonuses?.length),
      withLatestFrom(this.bonusesQuery.depBonusActivated$),
      tap(([bonuses, depBonusActivatedValue]) => {
        if (depBonusActivatedValue && !this.isCryptoMethod && !this.activeBonus) {
          this.bonusesData.unselectBonus();
        }
        if (!this.isCryptoMethod) {
          const defaultBonus = this.defaultBonusForActivation(bonuses);
          if (this.isBonusesEnabled) {
            this.isBonus.next(true);
            this.selectBonus(defaultBonus);
          } else { this.selectBonus(null); }
        }
      }),
      map(([ bonuses ]) => bonuses)
    );
  }

    private setActiveCryptoBonus(): void {
      this.isCryptoMethodSubject.pipe(
        takeUntilDestroyed(this.destroyRef$),
        switchMap((isCryptoMethod) => {
          return combineLatest([
            this.bonusesQuery.selectAvailableDepositBonuses(String(this.maxMethodPrice)),
            this.bonusesQuery.selectAvailableCryptoDepositBonuses(isCryptoMethod),
          ]);
        }),
        filter(([ bonuses ]) => !!bonuses?.length && !!this.isCryptoMethod && !this.activeBonus))
        .subscribe(([ bonuses , activeCryptoBonus ]) => {
          const cryptoBonus = bonuses.find(bonus => bonus.id === activeCryptoBonus);
          activeCryptoBonus ? this.selectBonus(cryptoBonus) : this.selectBonus(null);
        });
  }

  private selectBonus(bonus: Nullable<Bonus>): void {
    if (bonus) {
      this.bonusesData.selectBonus(bonus, this.isCryptoMethod);
      this.adapter.change(bonus);
      this.isBonus.next(true)
    } else {
      this.bonusesData.unselectBonus(this.isCryptoMethod);
      this.adapter.change(null);
      this.isBonus.next(false)
    }
  }

  private defaultBonusForActivation(bonuses: Array<Bonus>): Nullable<Bonus> {
    return bonuses?.find((bonus) => bonus?.type === 'casino_linked') || bonuses[0]
  }
}
