import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Nullable, UnixTimer, unixTimer$ } from '@libs/utils';
import { SummaryComponent } from '@portal/payment';
import { P2pCardComponent, P2pLifetimeDialogComponent } from '@portal/payment/components';
import { PostActionAbstract } from '@portal/payment/shared';
import { P2pCardInfo } from '@portal/payment/shared/types/p2p-card-info';
import { ControlsModule } from '@portal/shared/components';
import { StepperModule } from '@portal/shared/components/content/stepper';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { SpCommonModule } from 'libs/cdk/src/lib/common';
import { SpLocalizationModule } from 'libs/cdk/src/lib/localization';
import { filter, merge, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  templateUrl: './p2p.component.html',
  styleUrl: './p2p.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ SpCommonModule, P2pCardComponent, ControlsModule, SpLocalizationModule, StepperModule, SummaryComponent ],
})
export class P2pComponent extends PostActionAbstract implements OnInit {
  private readonly dialog = inject(Dialog);
  private readonly destroy = inject(DestroyRef);

  private ref: Nullable<DialogRef<boolean>> = null;

  readonly cancelButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Gray };
  readonly submitButton: IButton = { loading: false };

  readonly cardData: P2pCardInfo = this.collectPaymentData();
  readonly timer$: Nullable<Observable<UnixTimer>> = this.cardData.expireTime ? unixTimer$(this.cardData.expireTime) : null;

  @ViewChild('tpl', { read: TemplateRef, static: true }) tpl: Nullable<TemplateRef<unknown>>;

  ngOnInit(): void {
    this.ref = this.tpl ? this.dialog.open(this.tpl, { panelClass: [ 'bottom-center', 'bottom-center--new-cashier' ] }) : null;
    const dialog = this.cardData.initAmount ? this.openP2pLifetimeDialog() : null;

    if (this.processErrors(!this.cardData.account)) { return; }

    this.createDepositHandlers().pipe(
      tap(() => this.ref?.close(false)),
      tap(() => this.paramsData.setPayment({ section: 'deposit', group: null, method: undefined })),
      takeUntilDestroyed(this.destroy)
    ).subscribe(() => {
      dialog?.close();
    });
  }

  resetMethod(): void {
    this.paramsData.setPayment({ section: 'deposit', group: null, method: undefined });
    this.cardData.initAmount && this.openP2pLifetimeDialog();
  }

  approvePayment(): void {
    this.paymentData.checkStatus(this.cardData.id).pipe(
      tap(() => this.close(false)),
      takeUntilDestroyed(this.destroy),
    ).subscribe(({ status, url, initiatorId }) => {
      !this.processUrl(url) && this.setStatus({ status, invoice: initiatorId || undefined });
    });
  }

  private close(showDeposit = true): void {
    this.builder.reopen();
    this.ref?.close(showDeposit);
  }

  private collectPaymentData(): P2pCardInfo {
    const {
      target_card_number,
      target_phone_number,
      valid_till,
      method_flow,
      target_holder,
      target_bank,
      order_id,
      init_amount,
      reset_blocked_creds
    } = this.result.data;
    return {
      account: target_card_number || target_phone_number,
      expireTime: valid_till,
      type: method_flow,
      cardHolder: target_holder,
      bankCardName: target_bank,
      id: order_id,
      initAmount: init_amount,
      resetHint: reset_blocked_creds?.hint,
      resetTime: reset_blocked_creds?.expiration_time,
    };
  }

  private createDepositHandlers(handlers: Array<Observable<boolean>> = []): Observable<boolean> {
    this.timer$ && handlers.push(this.timer$.pipe(map(({ expired }) => expired)));

    return merge(...handlers).pipe(filter(Boolean));
  }

  private openP2pLifetimeDialog(): DialogRef<unknown, P2pLifetimeDialogComponent> {
    return this.dialog.open(P2pLifetimeDialogComponent, {
      panelClass: [ 'bottom-center', 'bottom-center--new-cashier' ],
      data: { timer: this.timer$, amount: this.cardData.initAmount },
    });
  }
}
