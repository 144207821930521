<section class="reset-warning" >
  <img src="assets/images/icons/cross.svg" alt="" class="reset-warning__close" (click)="close()"/>
  <div class="reset-warning__wrapper">
    <img src="assets/images/cashier/warning.svg" class="reset-warning__img" alt=""/>
    <div class="reset-warning__information">
      <sp-msg msg="CASHIER.CARD.P2P.RESET_CREDITS_MESSAGE"/>
    </div>


<!--    TODO: in 865 task-->
<!--    <sp-msg msg="CASHIER.CARD.P2P.CONFIRMATION_RESET"/>-->
<!--    <gg-button class="active-bonus-verification__button" [settings]="okButton" (click)="close()">-->
<!--      <sp-msg msg="CASHIER.CARD.P2P.CONFIRMATION_RESET_BUTTON"/>-->
<!--    </gg-button>-->

    <div class="reset-warning-chat" (click)="openChat()">
      <span class="reset-warning-chat__text" spMsg="COMMON.SUPPORT"></span>
    </div>
  </div>
</section>
