import { Nullable } from '@libs/utils';
import { FormDeclaration, PaymentStatus, PostActionDeclaration } from '@portal/payment/shared';

export class PaymentResult {
  constructor(
    readonly status: PaymentStatus,
    readonly data: {
      url?: string;
      initiatorId?: string;
      transaction_id?: string;
      qrcode?: string;
      target_card_number?: string;
      valid_till?: number;
      order_id?: string;
      code?: string;
      actual_payment_id?: string;
      target_holder?: string;
      target_bank?: string;
      target_phone_number?: string;
      method_flow?: 'p2p_sbp' | 'p2p_card';
      init_amount?: string;
      payment_data?: FormDeclaration;
      reset_blocked_creds?: {
        hint: boolean;
        expiration_time: Nullable<number>;
      };
    },
    readonly action: Nullable<PostActionDeclaration> = null,
    readonly message: string = '',
  ) {}
}
