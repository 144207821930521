import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Nullable } from '@libs/utils';
import { Bonus, BonusesData, BonusesQuery } from '@portal/bonuses/data';
import { ButtonSizes, ButtonThemes, IButton } from '@portal/shared/components/controls';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import cloneDeep from 'lodash.clonedeep';

@Component({
  selector: 'gg-payment-bonuses-selector',
  templateUrl: './bonuses-selector.component.html',
  styleUrls: [ 'bonuses-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusesSelectorComponent implements OnInit {
  private readonly bonusesQuery = inject(BonusesQuery);
  private readonly bonusesData = inject(BonusesData);
  private readonly isBonus: BehaviorSubject<Nullable<boolean>> = new BehaviorSubject<Nullable<boolean>>(false);

  readonly isBonus$: Observable<Nullable<boolean>> = this.isBonus.asObservable();
  readonly applyButton: IButton = { size: ButtonSizes.Medium, theme: ButtonThemes.Green };

  bonusesList: Nullable<Observable<Array<Bonus>>> = null;
  selectedBonus: Nullable<Bonus> = null;
  cryptoMethod: Nullable<boolean> = false;

  @Input()
  set isCryptoMethod(value: Nullable<boolean>) {
    this.cryptoMethod = value;
  }

  @Input()
  set amount(value: Nullable<number>) {
    this.bonusesQuery.selectAvailableDepositBonuses(String(value))
      .pipe(
        first(),
        filter((bonuses) => !!bonuses?.length && !!this.activeBonus))
      .subscribe(bonuses => {
        const isBonus = bonuses.some(bonus => bonus.id === this.activeBonus?.id);
        this.isBonus.next(isBonus);
      })
  }

  @Input()
  set maxMethodPrice(value: Nullable<number>) {
    this.bonusesList = this.bonusesQuery.selectAvailableDepositBonuses(String(value));
  }

  @Output() selected: EventEmitter<Nullable<Bonus>> = new EventEmitter<Nullable<Bonus>>();

  get activeBonus(): Nullable<Bonus> { return this.bonusesQuery.active; }

  ngOnInit(): void {
    this.selectedBonus = this.activeBonus;
  }

  apply(): void {
    this.selectedBonus ? this.bonusesData.selectBonus(this.selectedBonus, this.cryptoMethod) : this.bonusesData.unselectBonus(this.cryptoMethod)
    this.selected.emit(cloneDeep(this.selectedBonus));
  }

  bonusSelected(bonus?: Nullable<Bonus>): void {
    this.selectedBonus = bonus;
    this.isBonus.next(!!bonus);
  }
}
